export { default as useStateRef } from "./useStateRef";

/** LocalStorage */
export const setLocalStorage = (key: any, value: any) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // catch possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
};

export const getLocalStorage = (key: any, initialValue: any) => {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    // if error, return initial value
    return initialValue;
  }
};

export const usersDrawerKey = (user_id?: string) =>
  `VIS-${user_id}_Drawer_Open`;

export const getHostname = () => {
  if (process.env.NEXT_PUBLIC_HOSTNAME) {
    // if we are setting one in the environment
    return process.env.NEXT_PUBLIC_HOSTNAME;
  }

  if (typeof window !== "undefined") {
    // otherwise get it from the broswer
    return window.location.hostname;
  }

  return "saas.visionable.one"; // otherwise hardcode to saas
};

export const getServiceUrls = () => {
  const hostname = getHostname();
  const [serviceName, ...[domain]] = hostname.split(/\.(.*)/s); // split out the serviceName from the rest of the host
  const urls = {
    api: `${serviceName}-api.${domain}`,
    msg: `${serviceName}-msg.${domain}`,
    auth: `${serviceName}-auth.${domain}`,
    ucs: `${serviceName}-ucs.${domain}`,
  };

  return urls;
};

// GET A COLLOR BASED ON A STRING
export const stringToColor = (string: string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value?.toString(16)}`.substr(-2);
  }
  return color;
};

// EXTRACT USER INITIALS AND COLOR
export const getOwnerInitials = (
  name: string,
  variant: string,
  disabled?: boolean
) => {
  return {
    sx:
      variant === "small"
        ? {
            bgcolor: disabled ? "gray" : stringToColor(name),
            height: 25,
            width: 25,
            fontSize: "14px",
            fontWeight: "700",
            fontFamily: "Nunito Sans",
            fontStyle: "normal",
          }
        : {
            bgcolor: stringToColor(name),
          },

    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};

// DEBOUNCE FUNCTION
export const debounce = (func: any, delay: any) => {
  const time = delay;
  let timer: any;
  return function (event: any) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, time, event);
  };
};
