import * as CTypes from "../types";
import { getServiceUrls } from "@utilities";
import * as _log from "../_log";

/** Send meeting guest mjwt request */
const authorizeMeetingGuest = async ({
  meetingId,
  name,
}: {
  /** ID of meeting to join */
  meetingId: string;

  /** Name of guest */
  name: string;
}) => {
  // Url
  const { api } = await getServiceUrls();
  const url = `https://${api}/api/meeting/${meetingId}/join/guest`;

  // Init
  const init: RequestInit = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify({ name }),
  };

  // Make request
  try {
    const res = await fetch(url, init);
    if (!res || !res.ok) {
      throw new Error("Can't get mjwt as guest");
    }

    // Return mjwt
    const mjwt = res.text();
    return mjwt;
  } catch (e) {
    _log.error(e);
    return null;
  }
};

export default authorizeMeetingGuest;
