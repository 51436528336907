import { getServiceUrls } from "@utilities";
import { getCurrentUserInfo } from "../../@cognito";
import * as CTypes from "../types";

/** Retrieve the chat history for this conversation */
const getChatHistory = async (room: CTypes.Room) => {
  const { api } = getServiceUrls();

  const url =
    room.type === "MUC"
      ? `https://${api}/api/meetings/${room.room_id}/messages`
      : `https://${api}/api/messages/${room.room_id}`;

  // TODO Guest
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;

  try {
    const res = await fetch(url, {
      method: "GET",
      headers: { Authorization: jwt },
    });
    return res.json();
  } catch (e) {
    console.log("Error fetching inbox", e);
  }
};

export default getChatHistory;
