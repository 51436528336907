import * as _utils from "../_utils";
import { SDKActivationError, UserError } from "@chat/_errors";
import * as CTypes from "../types";

const addContact = ({
  id,
  isSignedIn,
  _status,
  _websocket,
  contacts,
  xmppServer,
}: {
  id: string;
  isSignedIn: CTypes.SDK["isSignedIn"];
  _status: CTypes.LoadStatus;
  _websocket: CTypes.Websocket;
  contacts: CTypes.ContactList;
  xmppServer: CTypes.XMPPServerData;
}) => {
  // Validate state
  if (!isSignedIn || _status !== "active" || !_websocket) {
    throw new SDKActivationError("Can't add contact, not signed in.");
  }

  // Ensure user exists and is not already a contact
  if (!contacts[id]) {
    throw new UserError("Can't add contact, user id not found.");
  }
  if (contacts[id].contact) {
    throw new UserError("Can't add contact, user already a contact.");
  }

  // Subscribe to contact
  _websocket.subscribe(_utils.formatJID(id, xmppServer));
};

export default addContact;
