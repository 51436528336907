import { APIServerError } from "../_errors";
import { getCurrentUserInfo } from "@cognito";
import * as ChatTypes from "../types";
import * as Types from "types";
import { getServiceUrls } from "@utilities";

/** Create an instant meeting */
const createInstantMeeting = async (
  /** User ID of invitee */
  invitee?: ChatTypes.ID
) => {
  // TODO: Maybe add a password so nobody else can join?
  // TODO: Set mgroup to 0 to enable inviting other people to meeting,
  //       using a passcode that gets sent along with meeting invites?

  // API endpoint
  const { api } = getServiceUrls();
  const url = `https://${api}/api/meetings/`;

  // Auth token
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;

  // Request parameters
  const startDate = new Date();
  const startTime = Math.round(startDate.getTime() / 1000);
  const body = {
    name: `Instant meeting`,
    mgroup: 1,
    start: startTime,
    hidden: true,
    type: "instant",
    invitees: invitee ? [invitee] : [],
  };

  // Request init
  const init = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: jwt,
    },
    body: JSON.stringify(body),
  };

  // Make request
  const res = await fetch(url, init);

  // Handle response
  if (!res.ok) {
    const error = await res.text();
    throw new APIServerError(error);
  }

  const meeting: Types.VTMeetings = await res.json();
  return meeting;
};

export default createInstantMeeting;
